<template>
    <div class="activity flex">
        <div class="left-name flex">
            <div>{{ $t('activity.desc1') }}</div>
            <p class="flex" @click="handlerInvite">{{ $t('activity.desc2') }} <img src="../public/images/new/activityicon.png" alt="" /></p>
        </div>
        <div class="activityicon" @click="handlerClose">
            <img src="../public/images/new/activityicon1.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        methods:{
            handlerInvite() {
                if(localStorage.getItem('token') == null){
                    this.$router.push('/login')
                }else{
                    this.$router.push('/invite')
                }
            },
            handlerClose(){
                this.$emit('handlerClose')
                localStorage.setItem('showActivity',1)
            }
        }
    }
</script>

<style lang="less" scoped>
.activity{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    z-index: 1000;
    .left-name{
        flex: 1;
        
        div{
            flex: 1;
           background: linear-gradient(90deg, #7BFAAB 0%, #4D48F5 73.36%, #9E44F6 100%);
            line-height: 52px;
            font-size: 18px;
            color: #000000;
            text-indent: 32px;
        }
        p{
            flex: 0 0 152px;
            background: #C9FA5B;
            line-height: 52px;
            font-size: 18px;
            color: #000000;
            justify-content: center;
            cursor: pointer;
            img{
                width: 8px;
                height: 14px;
                margin: 19px 0  0 8px;
            }
        }
    }
    .activityicon{
        flex: 0 0 52px;
        height: 52px;
        cursor: pointer;
        img{
            width: 52px;
            height: 52px;
       
        }
    }
}
</style>